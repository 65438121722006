<template>
  <div>
    <BaseCard
      title="datos de la factura de proveedor"
      :footer-visible="false"
      :loading="loading"
      @save-button-click="handleSaveButtonClick"
    >
      <PurchaseInvoiceProfileForm
        ref="purchase-invoice-profile-form"
        v-model="purchaseInvoice"
      />
      <hr class="m-0 mx-2">
      <PurchaseInvoiceBillingForm
        ref="purchase-invoice-billing-form"
        v-model="purchaseInvoice"
      />
    </BaseCard>
    <PurchaseInvoicesLinesTable edit-mode />
    <b-row
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          :disabled="loading"
          @click="handleSaveButtonClick"
        >
          Guardar factura
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PurchaseInvoicesApi from '@/api/purchase-invoices-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import PurchaseInvoiceProfileForm from '@/components/purchase-invoices/form/PurchaseInvoiceProfileForm.vue'
import PurchaseInvoiceBillingForm from '@/components/purchase-invoices/form/PurchaseInvoiceBillingForm.vue'
import PurchaseInvoicesLinesTable from '@/components/purchase-invoices/table/PurchaseInvoicesLinesTable.vue'

export default {
  name: 'Edit',
  components: {
    PurchaseInvoicesLinesTable, PurchaseInvoiceBillingForm, PurchaseInvoiceProfileForm, BaseCard,
  },
  data() {
    return {
      loading: false,
      purchaseInvoice: {},
    }
  },
  mounted() {
    this.loadPurchaseInvoice()
  },
  methods: {
    async loadPurchaseInvoice() {
      this.loading = true
      try {
        const response = await PurchaseInvoicesApi.get(this.$route.params.id)
        this.purchaseInvoice = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs['purchase-invoice-profile-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const { documentation, removedFiles } = this.$refs['purchase-invoice-profile-form'].getDocumentationData()
        await Promise.all([
          PurchaseInvoicesApi.edit(this.purchaseInvoice.id, { ...this.purchaseInvoice, documentation }),
          PurchaseInvoicesApi.deleteFiles(this.purchaseInvoice.id, removedFiles),
        ])
        await this.$router.go(-1)
      } catch (error) {
        this.$toast.error(error.response?.data?.error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
